/* eslint-disable no-undef */
import React, { Component }  from 'react' 
import PropTypes from 'prop-types' 
import {
  Spin,
  Tabs,
  Row,
  Col,
  Typography, 
} from 'antd'

import * as moment from 'moment'
import CompetitionScienceIcon from '../../../IconSVG/CompetitionScienceIcon.svg'
import CompetitionMathIcon from '../../../IconSVG/CompetitionMathIcon.svg'
import MathCATIcon from '../../../IconSVG/MathCATIcon.svg'
import ScienceCATIcon from '../../../IconSVG/ScienceCATIcon.svg'
import IconScienceExam from '../../../IconSVG/ExamScience.svg'
import IconMathExam from '../../../IconSVG/ExamMath.svg'
import CustomIconMath from '../../../IconSVG/CustomIconMath.svg'
import CustomIconScience from '../../../IconSVG/CustomIconScience.svg'
import ExerciseIconMath from '../../../IconSVG/ExerciseIconMath.svg'
import ExerciseIconScience from '../../../IconSVG/ExerciseIconScience.svg'

import Edit from '../../../IconSVG/Edit.svg'
import styles from '../../Exam.module.scss'
import '../CreateExamSet.scss'

const { TabPane } = Tabs
const { Paragraph } = Typography
const genInfoByObject = (data, title, labelSpanCol = null, infoSpanCol = null) => {
  if (!data) return null
  const labelCol = labelSpanCol
    ? { span: labelSpanCol }
    : {
      sm: 10,
      md: 8,
    }
  const infoCol = infoSpanCol
    ? { span: infoSpanCol }
    : {
      sm: 14,
      md: 16,
    }
  const children = []
  for (var key in data) {
    children.push(
      <Row key={key}>
        <Col className='infoLabel' {...labelCol}>{key}</Col>
        <Col className='infoText' {...infoCol}>{data[key]}</Col>
      </Row>
    )
  }
  return (
    <Row className={styles.infoPanel}>
      { title ? <p className='infoTitle'>{title}</p> : null}
      {children}
    </Row>
  )
}

const genInfoByArray = (arr) => { 
  if (!arr || arr.length < 1 ) return null
  const children = arr.map( (subArr, i) => {
    return (
      <div key={`${i}_${subArr.code}`}>
        <Col span={14}><Paragraph ellipsis={{ rows: 2, expandable: false}} title={i === 0 ? subArr[0] : subArr.name} style = {{color : '#828282'}}>{i === 0 ? subArr[0] : subArr.name}</Paragraph></Col>
        <Col className={'infoSubText textRight'} span={3} ellipsis={{ rows: 2, expandable: false }}><Paragraph ellipsis={{ rows: 2, expandable: false}} title={i === 0 ? subArr[1] : subArr.quantity} style = {{color : '#828282'}}>{i === 0 ? subArr[1] : subArr.quantity}</Paragraph></Col>
        <Col offset={2} span={3}><Paragraph className={ styles.paragraph_text_right } ellipsis={{ rows: 2, expandable: false}} title={i === 0 ? subArr[2] : subArr.quantity} style = {{color : '#828282'}}>{i === 0 ? subArr[2] : 'ข้อ'}</Paragraph></Col>
      </div>
    )
  })
  return (
    <Row className={styles.infoPanel}>
      {children}
    </Row>
  )
}

const displaydatetime = (a) =>
{
  let mytime = moment(a, 'YYYY-MM-DD HH:mm:ss').isValid()
  if (mytime === true)
    return moment(a, 'YYYY-MM-DD HH:mm:ss',true).add(543,'years').format('DD MMMM YYYY HH:mm:ss','th')
  else
    return ''
}

class StepFinal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      // displaySolutionTest: true
    }
  }

  componentDidMount = () => {
    this.props.setIsStepFinal()
  }

  // UNSAFE_componentWillReceiveProps = (nextProps) => {
  //   if(this.props.displaySolution !== nextProps.displaySolution) {
  //     this.setState({
  //       displaySolutionTest: nextProps.displaySolution
  //     })
  //   }
  // }

  // componentDidUpdate = (prevProps) => {
  //   if (this.props.displaySolution !== prevProps.displaySolution) {
  //     this.setState({
  //       displaySolutionTest: this.props.displaySolution
  //     })
  //   }
  // }

  onTabs = (key) => {
    // scrollToElement(`#${key}`)
    let element = document.getElementById(key)
    element.scrollIntoView({behavior: 'smooth' })
  }

  getExamLogo = () => {
    const { examType, subject } = this.props.data
    let logo
    if(examType === 'curriculum') {
      if(subject === 'คณิตศาสตร์') {
        logo = IconMathExam
      }else {
        logo = IconScienceExam
      }
    } else if(examType === 'competition'){
      if(subject === 'คณิตศาสตร์') {
        logo = CompetitionMathIcon
      }else {
        logo = CompetitionScienceIcon
      }
    }else if(examType === 'custom'){
      if(subject === 'คณิตศาสตร์') {
        logo = CustomIconMath
      }else {
        logo = CustomIconScience
      }
    } else if(examType === 'CAT') {
      if(subject === 'คณิตศาสตร์') {
        logo = MathCATIcon
      }else {
        logo = ScienceCATIcon
      }
    } else {
      if(subject === 'คณิตศาสตร์') {
        logo = ExerciseIconMath
      }else {
        logo = ExerciseIconScience
      }
    }
    return <img alt='icon-exam' src={logo} className={styles.iconScienceExam} style={{marginLeft: -14}}/>
  }

  render() {
    const {
      examType, subject, grade, level,
      criterion, quantityList, criterionQuantity,
      name, description, examSetTotal,  displayStartTime, displayEndTime, displayStartTime2, 
      displayEndTime2, displayShuffle, displayHowTo, oneTimeDone, testItem, duration, 
      withoutRegistered, displaySolution
    } = this.props.data  
    const { auth } = this.props
    const isTime = duration > 60 ? `${duration} นาที (${Math.floor((duration/60))} ชั่วโมง ${(duration)%60} นาที)` : `${duration} นาที`
    // const { displaySolutionTest } = this.state 

    const code = ''
    const examTypeText = examType === 'curriculumbook' ? 'แบบฝึกหัด' : examType === 'curriculum' ? 'ตามหลักสูตร' : examType === 'competition' ? 'แข่งขัน' : examType === 'custom' ? 'กำหนดเอง' : examType === 'E' ? 'แบบฝึกหัด' : 'CAT'
    const { onEdit } = this.props
    let selectQuantity = 0
    if (criterion === 'lesson') {
      selectQuantity = quantityList.reduce( (total, num) => total + num, 0 )
    } else if (criterion === 'indicator') {
      quantityList.forEach( items => selectQuantity = items.reduce( (total, num) => total + num, selectQuantity ) )
    } else if (criterion === 'none') {
      selectQuantity = examType !== 'custom' ?  quantityList[0] || 0 : testItem.length
    }

    const examSetInfo = (
      <div className='flex-space-between'>
        <div style={{display: 'flex'}}>
          {this.getExamLogo()}
          <div>
            <p className='text-medium' style={{color: '#05007A', fontSize: 18, marginBottom: 0}}>{name ? name : `${subject} ${grade}` }</p>
            <p className='text-regular' style={{color: '#2F2F2F', fontSize: 10, opacity: 0.5, marginBottom: 0 }}>{code}</p>
            <p className='text-regular' style={{color: '#2F2F2F', fontSize: 10, marginBottom: 0 }}>{examTypeText}</p>
          </div>
        </div>
      </div>
    )

    const infoSubject = genInfoByObject({
      'วิชา': subject,
      'ระดับชั้น': grade,
      'ระดับของข้อสอบ': level.join(', '),
    })

    const infoSubjectCat = genInfoByObject({
      'วิชา': subject,
      'ระดับชั้น/ช่วงชั้น': grade, 
    })

    let criterionText = ''
    let criterionList = []
    if (criterion === 'lesson') {
      criterionText = 'ตามบทเรียน'
      criterionList = criterionQuantity && criterionQuantity.lessons ? criterionQuantity.lessons.filter((t) => t.quantity !== 0): ''
    } else if (criterion === 'indicator') {
      criterionText = 'ตัวชี้วัด'
      criterionList = criterionQuantity ? criterionQuantity.indicators.map( t => [t.code, t.quantity, 'ข้อ']) : ''
      criterionList = criterionQuantity ? criterionQuantity.indicators.filter((t) => t.quantity !== 0) : ''
    } else if (criterion === 'none') {
      criterionText = 'ไม่ระบุ'
    } 
    const criteriaDetail = genInfoByArray([
      window.screen.width <= 991 ? [criterionText,'',selectQuantity] :[criterionText, selectQuantity, 'ข้อ'],
      ...criterionList,
    ])

    const infoCriteria = genInfoByObject({
      'รายละเอียด': criteriaDetail,
    }, 'จำนวนข้อสอบ')


    const infoCondition = genInfoByObject({
      'กำหนดเวลาในการทำข้อสอบ': duration > 0 ? isTime : 'ไม่ระบุ',
      'จำนวนชุดข้อสอบ': `${examSetTotal} ชุด`,
      'สุ่มสลับลำดับข้อสอบ/แบบฝึกหัด': displayShuffle ? 'ใช่' : 'ไม่ใช่',
      'กำหนดวันที่เริ่มการใช้ชุดแบบฝึกหัด': displayStartTime ? displaydatetime(displayStartTime2) : 'ไม่เปิดใช้',
      'กำหนดวันที่สิ้นสุดการใช้ชุดแบบฝึกหัด': displayEndTime ? displaydatetime(displayEndTime2) : 'ไม่เปิดใช้',
      'ให้นักเรียนแสดงวิธีทำ': displayHowTo ? 'ใช่' : 'ไม่ใช่',
      'เฉลยข้อสอบหลังทำเสร็จ':  displaySolution ? 'เฉลย' : 'ไม่เฉลย',
      'จำนวนครั้งในการทำข้อสอบ': oneTimeDone ? '1 ครั้ง' : 'ไม่จำกัด',
      'ทำได้โดยไม่ต้องสมัครสมาชิก': withoutRegistered ? 'อนุญาต' : 'ไม่อนุญาต',
      'คำชี้แจงในการทำข้อสอบ': description ? description : '-',

    }, 'เงื่อนไขของชุดข้อสอบ') 

    const infoConditionTeacher = genInfoByObject({
      'กำหนดเวลาในการทำข้อสอบ': duration > 0 ? isTime : 'ไม่ระบุ',
      'จำนวนชุดข้อสอบ': `${examSetTotal} ชุด`,
      'สุ่มสลับลำดับข้อสอบ/แบบฝึกหัด': displayShuffle ? 'ใช่' : 'ไม่ใช่',
      'กำหนดวันที่เริ่มการใช้ชุดแบบฝึกหัด': displayStartTime ? displaydatetime(displayStartTime2) : 'ไม่เปิดใช้',
      'กำหนดวันที่สิ้นสุดการใช้ชุดแบบฝึกหัด': displayEndTime ? displaydatetime(displayEndTime2) : 'ไม่เปิดใช้',
      'ให้นักเรียนแสดงวิธีทำ': displayHowTo ? 'ใช่' : 'ไม่ใช่',
      'เฉลยข้อสอบหลังทำเสร็จ':  displaySolution ? 'เฉลย' : 'ไม่เฉลย',
      'จำนวนครั้งในการทำข้อสอบ': oneTimeDone ? '1 ครั้ง' : 'ไม่จำกัด',
      'คำชี้แจงในการทำข้อสอบ': description ? description : '-',

    }, 'เงื่อนไขของชุดข้อสอบ') 

    const infoConditionStudent = genInfoByObject({
      'กำหนดเวลาในการทำข้อสอบ': duration > 0 ? isTime : 'ไม่กำหนด',
    }, 'เงื่อนไขของชุดข้อสอบ') 
    return (
      <div className='create-examset-final'>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          {/* <div className='pagination'>
            <Link style={{color: '#006FAD', margin: 0}} to={'/examset'}>{'ข้อสอบ >'}</Link>
            <span style={{color: '#828282'}}> รายละเอียด</span>
          </div>
          <h1 style={{fontSize: '28px', color: '#05007A', margin: 0, marginBottom: 10}}>ข้อสอบ</h1> */}
          {examSetInfo}
          <Tabs
            defaultActiveKey='anchorSubject'
            animated={false}
            onChange={this.onTabs}
          >
            <TabPane tab='วิชาและระดับ' key='anchorSubject'></TabPane>
            { examType !== 'cat' ? <TabPane tab='จำนวนข้อสอบ' key='anchorCriteria'></TabPane> :'' }
            { examType !== 'cat' ? <TabPane tab='เงื่อนไขของชุดข้อสอบ' key='anchorCondition'></TabPane> :'' }
          </Tabs>
          <div id='anchorSubject' className={styles.tabs_exam_detail}>
            {  examType !== 'cat' && examType !== 'competition' ? infoSubject : infoSubjectCat}
            <div className={styles.edit_step_final} onClick={(e) => {e.stopPropagation(); onEdit(1)}}><span><img  src={Edit}/>แก้ไข</span></div>
          </div>
          { 
            examType !== 'cat' ?
              <div>
                {/* <Divider style={{display: 'inline-block'}}/> */}

                <div id='anchorCriteria' className={styles.tabs_exam_detail}>
                  {infoCriteria}
                  <div className={styles.edit_step_final} onClick={(e) => {e.stopPropagation(); onEdit(2)}}><span><img  src={Edit}/>แก้ไข</span></div>
                </div>
                {/* <Divider style={{display: 'inline-block'}}/> */}
      
                <div id='anchorCondition' className={styles.tabs_exam_detail}>
                  {auth.role === 'student' ? infoConditionStudent : auth.role === 'teacher' ? infoConditionTeacher :infoCondition}
                  {/* { auth.role !== 'student' ?  infoCondition : infoConditionStudent infoConditionTeacher} */}
                  <div className={styles.edit_step_final}  onClick={(e) => {e.stopPropagation(); onEdit(3)}}><span><img  src={Edit}/>แก้ไข</span></div>
                </div>
              </div> 
              : ''
          }
        </Spin>
      </div>
    )
  }
}

StepFinal.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StepFinal
