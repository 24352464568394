import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  message,
  Spin,
  Button,
  Row,
  Col
} from 'antd'
import {
  GetContactDetail
} from '../../redux/master/action'
import EditIcon from '../IconSVG/icon_edit.svg'
import styles from './ContactUs.module.scss'
import EditContactUs from './EditContactUs'
class ContactUs extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      contactDetail:'',
      checkEdit : false
    }
  }

  componentDidMount(){
    this.fetchContactUsDetail()
  }
  
  // UNSAFE_componentWillReceiveProps(nextProps){
  //   if(nextProps.action === nextProps.action){
  //     this.setState({
  //       checkEdit : false
  //     })
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.action !== this.props.action) {
      this.setState({
        checkEdit: false
      })
    }
  }
  
  fetchContactUsDetail = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.GetContactDetail()
    this.setState({ 
      isLoading: false,
      contactDetail : res
    })
    if (res.error) message.error(res.error)
  }
  goToEditComponent = () =>{
    this.setState({
      checkEdit : !this.state.checkEdit
    })
  }

  render() {
    return ( 
      <div>
        {
          this.state.checkEdit ? 
            <div>
              <EditContactUs goToEditComponent = {this.goToEditComponent} fetchContactUsDetail = {this.fetchContactUsDetail} />
            </div>
            :
            <div>
              <div className={styles.examSetDiv}>
                <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
                  <Row>
                    <div className='flex-space-between' style={{marginBottom: '30px'}}>
                      <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>ติดต่อเรา</h1>
                      <div style={{display: 'flex'}}>
                        <Button type='primary' onClick = {this.goToEditComponent}>
                          <img src = {EditIcon} style ={{marginRight : 5}}/>
                          <label>แก้ไขข้อมูล</label>
                        </Button>
                      </div>
                    </div>
                  </Row>
                  <div>
                    <Row span ={24}>
                      <Row className = {styles.row_table_contact_us}>
                        <Col span ={2} className = {styles.td_table_in_contact_us}><label>ชื่อองค์กร</label></Col>
                        <Col span ={6} className = {styles.td_right_in_contact_us}><div className = {styles.detail_name}>{this.state.contactDetail ? this.state.contactDetail.name : '' } </div></Col>
                      </Row>
                      <Row className = {styles.row_table_contact_us}>
                        <Col span ={2} className = {styles.td_table_in_contact_us}><label>อีเมล</label></Col>
                        <Col span ={6} className = {styles.td_right_in_contact_us}><div className = {styles.detail_name}>{this.state.contactDetail ? this.state.contactDetail.email : '' } </div></Col>
                      </Row>
                      <Row className = {styles.row_table_contact_us}>
                        <Col span ={2} className = {styles.td_table_in_contact_us}><label>โทร</label></Col>
                        <Col span ={6} className = {styles.td_right_in_contact_us}><div className = {styles.detail_name}>{this.state.contactDetail ? this.state.contactDetail.tel : '' } </div></Col>
                      </Row>
                      <Row className = {styles.row_table_contact_us}>
                        <Col span ={2} className = {styles.td_table_in_contact_us}><label>ที่อยู่</label></Col>
                        <Col span ={6}className = {styles.td_right_in_contact_us}>
                          <div className = {styles.detail_address}>{this.state.contactDetail ? this.state.contactDetail.address : '-' } </div> 
                        </Col>
                      </Row>
                    </Row>
                  </div>
                </Spin>
              </div>
            </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    contactDetail : state.master.contactDetail
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetContactDetail,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)