import React from 'react'
import PropTypes from 'prop-types'
import {
  Input
} from 'antd'
import styles from './StepperCircle.module.scss'

const numberRegEx = /^\d+$/

class StepperCircle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
    }
  }

  componentDidMount = () => {
    if (this.props.value !== undefined) this.setState({ value: this.props.value})
  }

  // UNSAFE_componentWillReceiveProps = (nextProps) => {
  //   if(this.props.value !== nextProps.value) this.setState({ value : nextProps.value })
  // }

  componentDidUpdate = (prevProps) => {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value })
    }
  }

  onStepperChange = e => {
    let { value } = e.target
    value = this.getNumber(value)
    this.setState({ value })
    this.props.onChange(value)
  }

  onStep = step => {
    const max = this.props.max !== undefined ? this.props.max : 9999
    let { value } = this.state
    value = Math.min(max, Math.max(0, this.getNumber(value) + step))
    this.setState({ value })
    this.props.onChange(value)
  }

  getNumber = value => {
    if (value !== '' && !numberRegEx.test(value)) return
    const max = this.props.max !== undefined ? this.props.max : 9999
    value = Math.min(max, Math.max(0, !value ? 0 : parseInt(value)))
    return value
  }

  render() {
    const value = this.props.value !== undefined && (typeof(this.props.value) === 'number') ? this.props.value : this.state.value
    return (
      <div className={styles.inputStepCircle}>
        <div className={styles.circle} onClick={()=>this.onStep(-5)}>-5</div>
        <div className={`${styles.circle} ${styles.circleSmall}`} onClick={()=>this.onStep(-1)}>-1</div>
        <Input className={styles.inputStepper} value={value} onChange={this.onStepperChange} maxLength={4}/>
        <div className={`${styles.circle} ${styles.circleSmall}`} onClick={()=>this.onStep(1)}>+1</div>
        <div className={styles.circle} onClick={()=>this.onStep(5)}>+5</div>
      </div>
    )
  }
}

StepperCircle.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  max: PropTypes.number,
}

export default StepperCircle
