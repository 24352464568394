import React, { Component, Fragment }  from 'react'
import {
  Row,
  Col,
  // Divider,
  Button,
  // Input
} from 'antd'
import {
  Link
} from 'react-router-dom'

import StudentPictureGroup from '../../IconSVG/StudentPictureGroup.svg' 
import ExamSetInfo from './examSetInfo' 

import styles from '../Exam.module.scss'
// import { async } from 'q';


const genInfoByObject = (data, title, labelSpanCol = null, infoSpanCol = null) => {
  if (!data) return null
  const labelCol = labelSpanCol
    ? { span: labelSpanCol }
    : {
      sm: 14,
      md: 6,
    }
  const infoCol = infoSpanCol
    ? { span: infoSpanCol }
    : {
      sm: 10,
      md: 18,
    }
  const children = []
  for (var key in data) {
    children.push(
      <Fragment key={key}>
        <Col className='infoLabel' {...labelCol}>{key}</Col>
        <Col className='infoText' {...infoCol} >{data[key]}</Col>
      </Fragment>
    )
  }
  return (
    <Row className={styles.infoPanel}>
      { title ? <p className='infoTitle'>{title}</p> : null}
      {children}
      {/* <Divider style={{display: 'inline-block'}}/> */}
    </Row>
  )
}

class DetailExamSetStudentComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      canDoTesting: true,
      textButton: 'เริ่มทำ'
    }
  }
 
  // UNSAFE_componentWillReceiveProps = (nextProps) => { 
  //   let { canDoTesting,textButton } = this.state
  //   if(this.props.examDetail !== nextProps.examDetail) {
  //     if(nextProps.examDetail.testing[0]){
  //       if(nextProps.examDetail.testing[0].finishedAt){
  //         textButton = 'ทำซ้ำ'
  //         canDoTesting = nextProps.examDetail.oneTimeDone ? false : true
  //       }else {
  //         textButton = 'ทำต่อ'
  //       }
  //     } 
  //   }
  //   this.setState({ canDoTesting, textButton})
  // }

  componentDidUpdate = (prevProps) => {
    let { canDoTesting, textButton } = this.state
    if (this.props.examDetail !== prevProps.examDetail) {
      if (prevProps.examDetail.testing[0]) {
        if (prevProps.examDetail.testing[0].finishedAt) {
          textButton = 'ทำซ้ำ'
          canDoTesting = prevProps.examDetail.oneTimeDone ? false : true
        } else {
          textButton = 'ทำต่อ'
        }
      }
    }
  }

  render() {
    const { match, examDetail, history } = this.props  
    const { canDoTesting , textButton } = this.state 
    let linkToTesting = '/'
    let stateParam = { cameFrom: 'exam' }   
    if(match.path === '/suggest/:id'){  
      stateParam = { cameFrom: 'suggest' }
      if(match.params && match.params.groupId){ 
        linkToTesting = `/testing/group/${match.params.groupId}/examset/${match.params.id}` 
      }else{ 
        linkToTesting = `/testing/${examDetail._id}`
      }
    }else{  
      if(history.location.state && history.location.state.groupId) {
        stateParam = { cameFrom: 'group', groupId: history.location.state.groupId, groupName: history.location.state.groupName }
      }
      if(match.params.groupId){  
        linkToTesting = `/testing/group/${match.params.groupId}/examset/${match.params.id}`  
      }else{ 
        linkToTesting = `/testing/${examDetail._id}` 
      }
    }   
    const examTime = (examDetail.duration/60) > 60 ? `${examDetail.duration/60} นาที (${Math.floor((examDetail.duration/60)/60)} ชั่วโมง ${(examDetail.duration/60)%60} นาที)` : `${examDetail.duration/60} นาที`
    const checkProjectOfExam =  examDetail.competition ? examDetail.competition.project+'' : '-'
    const projectOfExam = checkProjectOfExam === 'undefined' ? 'ไม่มี' : checkProjectOfExam

    const infoSubjectTypeC = genInfoByObject({
      'วิชา' : examDetail.subject ? examDetail.subject : 'ไม่ระบุ',
      'ระดับชั้น' : examDetail.grade ? examDetail.grade : 'ไม่ระบุ',
      'โครงการที่แข่งขัน' : projectOfExam ? projectOfExam : 'ไม่มี',
      'ปีที่แข่งขัน': examDetail.competition ? examDetail.competition.years.join(', ') : 'ไม่ระบุ',
      'จำนวนข้อสอบ': examDetail.quantity ? `${examDetail.quantity} ข้อ` : 'ไม่ระบุ',
      'กำหนดเวลาในการทำข้อสอบ': examDetail.duration === 0 ? 'ไม่ระบุ' : examTime,
      'คำชี้แจงในการสอบ': examDetail.description ? examDetail.description : 'ไม่ระบุ',
    })

    const infoSubjectTypeG = genInfoByObject({
      'วิชา' : examDetail.subject ? examDetail.subject : 'ไม่ระบุ',
      'ระดับชั้น' : examDetail.grade ? examDetail.grade : 'ไม่ระบุ',
      'จำนวนข้อสอบ': examDetail.quantity ? `${examDetail.quantity} ข้อ` : 'ไม่ระบุ',
      'กำหนดเวลาในการทำข้อสอบ': examDetail.duration === 0 ? 'ไม่ระบุ' : examTime,
      'คำชี้แจงในการสอบ': examDetail.description ? examDetail.description : '-',
    })

    
    const infoSubject = examDetail.type === 'C' ? infoSubjectTypeC :  infoSubjectTypeG 
    return (
      <div className={styles.examset_detail_student}>
        <ExamSetInfo
          examDetail={examDetail}
          examSetDetail={this.state.examSetDetail}
          {...this.props}
        />

        <div id='anchorSubject' className={styles.detail_my_exam}>
          {infoSubject}
        </div>

        <div className='actions'>
          {
            ['ป.1','ป.2','ป.3','ป.4','ป.5','ป.6'].includes(examDetail.grade) ? (
              <div className={styles.div_pic_group}>
                <img src={StudentPictureGroup} className={styles.studentPictureGroup}/>
              </div>
            ) : (
              <div className={styles.div_pic_group}/>
            )
          }

          {
            (canDoTesting && examDetail.status && !examDetail.deletedAt && examDetail.type !== 'CAT') || ( examDetail.type === 'CAT' && examDetail.testing.length === 0)?
              <div className={styles.div_start_btn}>
                <Link to={'/examset'}>
                  <p className={`${styles.backwardBtn} desktop`}>กลับ</p>
                </Link>  
                {
                  history.location.state && history.location.state.groupId ? 
                    <Link to={{ pathname:  `/testing/group/${history.location.state.groupId}/examset/${match.params.id}`,state: stateParam }}>
                      <Button type='primary' className={styles.buttonStartTest}>{textButton}</Button>
                    </Link> 
                    :
                    <Link to={{ pathname:  `${linkToTesting}`,state: stateParam }}>
                      <Button type='primary' className={styles.buttonStartTest}>{textButton}</Button>
                    </Link> 
                }
                <Link to={'/examset'}>
                  <p className={`${styles.backwardBtn} mobile`}>กลับ</p>
                </Link>
              </div>
              :
              ''
          }
        </div>
      </div>
    )
  }
}

export default DetailExamSetStudentComponent
