import React, { Component, Fragment }  from 'react'
import PropTypes from 'prop-types' 
import {
  Row,
  Col,
  Divider,
  Button,
  Modal,
  Collapse,
  Table,
  Input, 
} from 'antd'

import * as _ from 'lodash'

import { DndProvider, DragSource, DropTarget } from 'react-dnd-cjs'
import HTML5Backend from 'react-dnd-html5-backend-cjs'
import update from 'immutability-helper' 
import ExamWarning from '../../../IconSVG/ExamWarning.svg'
import IconMoveRow from '../../../IconSVG/IconMoveRow.svg'
import IconPlus from '../../../IconSVG/IconPlus.svg'
import IconMinus from '../../../IconSVG/IconMinus.svg'
// import IconLesson from '../../../IconSVG/IconLessons.svg'
import { cloneArray } from '../../../../util/helper'
import StepperCircle from '../../../Display/StepperCircle'
import styles from '../../Exam.module.scss'
import '../CreateExamSet.scss' 
import IconLesson from '../../../IconSVG/IconLessons.svg'
import IconGrayLesson from '../../../IconSVG/IconGrayLessons.svg'
import IconNone from '../../../IconSVG/IconNone.svg'
import IconNoneGray from '../../../IconSVG/IconNoneGray.svg'
import IconIndicator from '../../../IconSVG/IconIndicator.svg'
import IconGrayIndicator from '../../../IconSVG/IconGrayIndicator.svg'


let dragingIndex = -1

class BodyRow extends React.Component {
  render() {
    const { isOver, connectDragSource, connectDropTarget,   ...restProps } = this.props
    const style = { ...restProps.style, cursor: 'move' }

    let { className } = restProps
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward'
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward'
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    )
  }
}
const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index
    return {
      index: props.index,
    }
  },
}

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index
    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }
    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex)
    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  },
}

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
)

const { Panel } = Collapse

const labelCol = {
  xs: 24,
  sm: 10,
  md: 12,
}
const inputCol = {
  xs: 24,
  sm: 14,
  md: 12,
}

const MAX_SELECT_QUANTITY = 50

class StepCriterion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [ { key: 1,placeholder: 'กรอกรหัสข้อสอบ',code: null, codeError: false } ],
      countKey: 1,
      rowIndex: 0,
      checkErrorCode: null,
      indexRow: null, 
      selectQuantity: 0,
      totalQuantity: 0,
      inputQuantityList: []
    } 
  }
  columns = [
    { 
      key: 'iconMoveRow',
      render: () => {
        return (<div className={ styles.column_fill_code1 }><img src={IconMoveRow}/></div>)
      },
      // className: 'test_column',
      // width: '15px'
    },
    { 
      key: 'numberOfRange', 
      render: (num,row,index) => {
        return (<div className={ styles.column_fill_code2 }><span>{index+1}</span></div>)
      },
      // width: '60px',
    },
    { 
      key: 'code',
      dataIndex: 'placeholder',
      render:(placeholder,record,index)=>{ 
        // const {checkErrorCode,indexRow }  = this.state 
        return (
          <div className={ styles.column_fill_code3 }>
            <Input 
              placeholder={placeholder}
              type='number'
              value={ record.code }
              onChange={(e) => {
                e.stopPropagation()
                this.onChangeInputCode(e.target.value,index)
              }}/>
            { record.codeError  && record.code ?  <p style={{ color: 'red',fontSize: '12px',fontWeight: '500' }}>รหัสข้อสอบไม่ถูกต้อง</p> : null }
          </div>
        )
      },
      // width: '250px'
    },
    { 
      key: 'PlusIcon',
      render: () => {
        return (
          <div className={ styles.column_fill_code4 }>
            <img onClick={(e)=> {
              e.stopPropagation()
              this.addRowExamCode()
            }} style={{ cursor: 'pointer' }} src={IconPlus}/>
          </div>
        )
      },
      // width: '15px',
    },
    { 
      key: 'MinusIcon',
      render: (data,record,index) => {
        return (
          <div className={ styles.column_fill_code5 }>
            <img onClick={(e)=>{
              e.stopPropagation()
              this.deleteRowExamCode(record.key)
              // this.props.deleteTestItem(index)
            }} style={ index === 0 ? { cursor: 'pointer',visibility: 'hidden' } : { cursor: 'pointer' }} src={IconMinus}/>
          </div>
        )
      },
      // width: '15px',
    },
  ]
  componentDidMount = () => { 
    let { inputQuantityList, selectQuantity, totalQuantity } = this.state
    const { grade, criterion, onChangeQuantity, quantityList, dataForCriterion } = this.props
    if (criterion === 'lesson' || criterion === 'strand') { 
      inputQuantityList = this.createLessonList(criterion)
      selectQuantity = quantityList.reduce( (total, num) => total + num, 0 )
      totalQuantity = dataForCriterion.reduce( (total, obj) => total + (isNaN(parseInt(obj.noitems)) ? 0 : parseInt(obj.noitems)), 0 )
    } else if (criterion === 'indicator') { 
      inputQuantityList = this.createIndicatorList()
      // quantityList.forEach( items => selectQuantity = items.reduce( (total, num) => total + num, selectQuantity ) )
      selectQuantity = quantityList.reduce((total, num) => total + num, selectQuantity ) 
      dataForCriterion.forEach( items => totalQuantity = items.indicators.reduce( (total, obj) => total + (isNaN(parseInt(obj.noitems)) ? 0 : parseInt(obj.noitems)), totalQuantity ) )
    } else if (criterion === 'none') { 
      selectQuantity = quantityList[0] || 0    
      if(grade === 'ม.1') {  
        onChangeQuantity(5)
        selectQuantity = 5
      }
    } 
    this.setState({ inputQuantityList, selectQuantity, totalQuantity })
  }
  // UNSAFE_componentWillReceiveProps = (nextProps) => {  
  //   const { onChangeQuantity  } = this.props 
  //   // const { criterion, dataForCriterion, quantityList } = this.props 
  //   // let { selectQuantity, totalQuantity, inputQuantityList } = this.state  
  //   if(this.props.grade !== nextProps.grade && nextProps.grade === 'ม.1'){  
  //     onChangeQuantity(5)
  //     this.setState({ 
  //       selectQuantity: 5
  //     })
  //   }  
  // }

  componentDidUpdate = (prevProps) => {
    const { onChangeQuantity, grade } = this.props
    if (prevProps.grade !== grade && grade === 'ม.1') {
      onChangeQuantity(5)
      this.setState({ selectQuantity: 5 })
    }
  }
 
  onCriterionChange = (criterion) => {
    this.props.onChangeCriterion({ criterion: criterion})
  }

  onQuantityChange = (index, quantity, indexH = undefined) => {
    const { quantityList } = this.props
    const newQuantityList = cloneArray(quantityList)
    
    let selectQuantity = 0
    if (indexH === undefined) {
      newQuantityList[index] = quantity
      selectQuantity = newQuantityList.reduce( (total, num) => total + num, 0 )
    } else { 
      if (!newQuantityList[indexH]) newQuantityList[indexH] = []
      newQuantityList[indexH][index] = quantity  
      newQuantityList.forEach( items => {
        selectQuantity = items.reduce( (total, num) => total + num, 0 )
      }) 
    }
    // newQuantityList[index] = quantity
    // selectQuantity = newQuantityList.reduce( (total, num) => total + num, 0 )
    if (selectQuantity > MAX_SELECT_QUANTITY) {
      Modal.error({
        closable: true,
        centered: true,
        okText: 'ตกลง',
        width: 580,
        icon: null,
        content: (
          <div style={{textAlign: 'center'}}> 
            <img src={ExamWarning} style={{ width: '256px' }}/>
            <h2 className='text-medium' style={{fontSize: 20}}>ข้อสอบเกินจำนวน</h2>
            <p style={{marginBottom: 0}}>สามารถเลือกข้อสอบได้ไม่เกิน {MAX_SELECT_QUANTITY} ข้อ</p>
            <p>ต่อ 1 ชุดข้อสอบ</p>
          </div>
        )
      })
      return
    }
    this.props.onChangeCriterion({ quantityList: newQuantityList})
  }

  createLessonList = (criterion) => {
    const { dataForCriterion, quantityList } = this.props
    if (!dataForCriterion) return [] 
    return dataForCriterion.map( (t, index) => {
      const noitems = parseInt(t.noitems)
      return (
        <Row key={t.code} style={{marginBottom: 10}}>
          <Col className='textLabel dark' style={ noitems <= 0 ? { color: '#828282' } : null } {...labelCol}>
            { criterion === 'strand' ? 
              <div >
                <span style={ noitems > 0 ? { margin: 0, fontWeight: 500, color: '#333333' } : { margin: 0, fontWeight: 500,color: '#828282' }}>
                  {`สาระ ${index+1} `}</span> 
                <span style={ noitems > 0 ? { margin: 0, fontWeight: 300, color: '#333333' } : { margin: 0, fontWeight: 300, color: '#828282'}}>{t.name}</span>
              </div>
              : t.name }
            <br/>
            { noitems > 0
              ? <span className='textHint'>(จำนวน {noitems} ข้อ)</span>
              : null }
          </Col>
          <Col className='inputField right' {...inputCol}>
            { noitems > 0
              ? <StepperCircle value={ quantityList[index] } onChange={ value => this.onQuantityChange(index, value) } max={noitems}/>
              : null }
          </Col>
        </Row>
      )
    })
  }

  createIndicatorList = () => {
    const { dataForCriterion, quantityList } = this.props
    if (!dataForCriterion) return []
    const defaultActive = []

    const panelList = dataForCriterion.map( (h, indexH) => {

      const indicatorList = h.indicators && h.indicators.map( (t, index) => {
        const noitems = parseInt(t.noitems)
        return (
          <Row key={t.code} style={{marginBottom: 10}}>
            <Col className='textLabel dark' style={ noitems <= 0 ? { color: '#828282' } : null } {...labelCol}>
              <span className='text-thin'><span className='text-regular'>{t.code}</span> {t.name}</span>
              <br/>
              { noitems > 0
                ? <span className='textHint'>(จำนวน {noitems} ข้อ)</span>
                : null }
            </Col>
            <Col className='inputField right' {...inputCol}>
              { noitems > 0
                ? <StepperCircle value={ (quantityList[indexH] && quantityList[indexH][index]) || 0 } onChange={ value => this.onQuantityChange(index, value, indexH) } max={noitems}/>
                : null }
            </Col>
          </Row>
        )
      }) || null
      defaultActive.push(`${indexH}`)
      return <Panel key={indexH} header={<span className='text-thin'><span className='text-regular'>สาระที่ {indexH+1} :</span> {h.name}</span>}>{indicatorList}</Panel>
    })
    return <Col span={24}>
      <Collapse expandIconPosition={'right'} defaultActiveKey={defaultActive}>{panelList}</Collapse>
    </Col>
  }

  addRowExamCode = () => {
    let { data,countKey } = this.state
    const { setExamByCode } = this.props
    if(countKey < 50){
      let newData = {
        key: countKey + 1,
        placeholder: 'กรอกรหัสข้อสอบ',
        code: null
      }
      this.setState({
        data: [...data,newData],
        countKey: countKey + 1
      })
      setExamByCode(data)
    }else{ 
      Modal.error({
        closable: true,
        centered: true,
        okText: 'ตกลง',
        width: 580,
        icon: null,
        content: (
          <div style={{textAlign: 'center'}}> 
            <img src={ExamWarning} style={{ width: '256px' }}/>
            <h2 className='text-medium' style={{fontSize: 20}}>ข้อสอบเกินจำนวน</h2>
            <p style={{marginBottom: 0}}>สามารถเลือกข้อสอบได้ไม่เกิน {MAX_SELECT_QUANTITY} ข้อ</p>
            <p>ต่อ 1 ชุดข้อสอบ</p>
          </div>
        )
      })
      return
    }
  }

  deleteRowExamCode = (key) => {
    let { data } = this.state 
    const { setExamByCode } = this.props
    data = data.filter((d) => d.key !== key)
    this.setState({ data })
    setExamByCode(data)
  }

  onChangeInputCode = (code,index) => {
    let { checkErrorCode, data } = this.state
    checkErrorCode = false
    data[index].code = code
    data[index].codeError = false
    
    this.setState({ checkErrorCode, indexRow: index, data })
    this.onCheckCode(code,index)
  }

  onCheckCode = _.debounce(async(code,index) => {
    let { checkErrorCode,data } = this.state
    const { checkExamByCode } = this.props
    let res = []
    if(code) res = await checkExamByCode(code,data,index)
    
    if( !res.result && res.length !== 0 ){
      checkErrorCode = true
      data[index].codeError = true
    }
    this.setState({ checkErrorCode })
  },1000)

  render() {
    const { onChangeCriterion, isCurriculum, criterion , examType, auth, onChangeQuantity, dataForCriterion, quantityList, book } = this.props
    let { data, selectQuantity, totalQuantity, inputQuantityList } = this.state      
    if (criterion === 'lesson' || criterion === 'strand') {	 
      inputQuantityList = this.createLessonList()	 
      selectQuantity = quantityList.reduce( (total, num) => total + num, 0 )	
      totalQuantity = dataForCriterion.reduce( (total, obj) => total + (isNaN(parseInt(obj.noitems)) ? 0 : parseInt(obj.noitems)), 0 )	
    } else if (criterion === 'indicator') {	  
      inputQuantityList = this.createIndicatorList()	 
      quantityList.forEach( (items,i) => { 
        if(Array.isArray(items)){
          selectQuantity = items.reduce( (total, num) => total + num, selectQuantity ) 
        }else{
          selectQuantity = items
        }
        
      })	  
      // selectQuantity = quantityList.reduce((total, num) => total + num, selectQuantity )  
      dataForCriterion.forEach( items => totalQuantity = items.indicators ? items.indicators.reduce( (total, obj) => total + (isNaN(parseInt(obj.noitems)) ? 0 : parseInt(obj.noitems)), totalQuantity ) : [] )	
    // totalQuantity = dataForCriterion.reduce((total, obj) => total + (isNaN(parseInt(obj.noitems)) ? 0 : parseInt(obj.noitems)), totalQuantity ) ? 0  
    }  else if (criterion === 'none') {	
      selectQuantity = quantityList[0] || 0	
    } 

    const inputCriterion = (
      <div className='input-criterion'>
        <Col className='textLabel' span={7} xs={24} md={7}>เกณฑ์การจัดข้อสอบ</Col> {/* DEBUG [{isCurriculum}][{criterion}]*/}
        <Col className='inputField' span={17} xs={24} md={17}>
          <div className={styles.button_switch}>
            <div className={ auth.role !== 'student' && examType === 'curriculumbook' ? styles.btn_none : auth.role !== 'student' && examType === 'curriculum' ? styles.btn_none : auth.role !== 'student' && examType === 'competition' ? styles.btn_none_c : styles.btn_none_student}>
              <Button
                type={criterion === 'none' ? 'primary' : 'secondary'} 
                // style={{minWidth: 110}}
                onClick={() => onChangeCriterion({ criterion: 'none' })}
              >
                <img src = { criterion === 'none' ? IconNone : IconNoneGray} /> 
                {/* <IconCriterionNone/><IconCriterionNoneGray className='iconGray'/> */}
              ไม่ระบุ
              </Button>
            </div>
            { isCurriculum ?
              <div className={ auth.role !== 'student' && examType === 'curriculumbook' ? styles.btn_primary_secondary : auth.role !== 'student' && examType === 'curriculum' ? styles.btn_primary_secondary : styles.btn_primary_secondary_student }>
                <Button
                  type={criterion === 'lesson' ? 'primary' : 'secondary'} 
                  // style={{minWidth: 110}}
                  onClick={async() => {
                      await onChangeCriterion({ criterion: 'lesson' })
                    onChangeQuantity(0)
                    this.setState({ selectQuantity: 0 })
                  }}
                >
                  <img src = {criterion === 'lesson' ? IconLesson : IconGrayLesson} /> 
                บทเรียน
                </Button>
              </div>
              : null
            } 
            {
              auth.role !== 'student' && examType !== 'curriculumbook' ?
                <div className={ auth.role !== 'student' && examType === 'curriculum' ? styles.btn_primary_third : auth.role !== 'student' && examType === 'competition' ? styles.btn_primary_third_c : styles.btn_primary_third_student }>
                  <Button
                    type={criterion === 'indicator' || criterion === 'strand' ? 'primary' : 'secondary'}
                    // style={{minWidth: 110}}
                    onClick={async() => { 
                      await onChangeCriterion( isCurriculum ? { criterion: 'indicator' } : { criterion: 'strand' })
                      onChangeQuantity(0)
                      this.setState({ selectQuantity: 0 })
                    }}
                  >
                    <img src = { criterion === 'indicator' || criterion === 'strand' ? IconIndicator : IconGrayIndicator} />
                    {/* <IconIndicator/><IconIndicatorGray className='iconGray'/> */}
                    { isCurriculum ? 'ตัวชี้วัด' : 'สาระการเรียนรู้' }
                  </Button>
                </div>
                : null
            }
          </div>
        </Col>
      </div>
    ) 
    const textSelectQuantity = (
      <div className='criterion-header'>
        <Col className='textHeader' span={7} xs={24} md={7}>
          จำนวนที่เลือก {selectQuantity === 0 ? '' : selectQuantity} ข้อ
          <br/>
          <span className='textHint'>(สูงสุด {MAX_SELECT_QUANTITY} ข้อ)</span>
        </Col>
        <Col className='inputField' span={17} xs={24} md={7}>
          {criterion === 'none' ? <StepperCircle value={ selectQuantity } onChange={ value => { this.setState({ selectQuantity: value });this.onQuantityChange(0, value) }} max={MAX_SELECT_QUANTITY}/> : null}
        </Col>
      </div>
    )

    const textBookName = (
      <Fragment>
        <Col className='textHeader' {...labelCol}> {/*textLabel*/}
          หนังสือ "{book}"
        </Col>
        <Col className='inputField' {...inputCol}></Col>
      </Fragment>
    )


    const textTotalQuantity = (
      <Fragment>
        <Col className='textLabel' {...labelCol}>
          จำนวนข้อทั้งหมด {totalQuantity} ข้อ
        </Col>
        <Col className='inputField' {...inputCol}></Col>
      </Fragment>
    )

    const components = {
      body: {
        row: DragableBodyRow,
      },
    } 
    const moveRow = (dragIndex, hoverIndex) => {
      const { setExamByCode } = this.props
      let { data } = this.state
      const dragRow = data[dragIndex] 
      this.setState(
        update(this.state, {
          data: {
            $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
          },
        }),
      ) 
      setExamByCode(data) 
    }

    const tableSubjectCode = (
      <Fragment>
        <DndProvider backend={HTML5Backend}>
          <Table
            rowKey={(record,i) => i}
            columns={this.columns}
            dataSource={data}
            components={components}
            pagination={false}
            showHeader={false}
            onRow={(record, index) => ({ 
              index,
              moveRow: moveRow,
            })}
            className={ styles.table_exam_code }
            // style={{ width: '460px' }}
          />
        </DndProvider>
      </Fragment>
    )

    return (
      <div className='create-examset-criterion'>
        <Row className={styles.createPanel}>
          {
            examType === 'custom' || examType === 'E' ?
              <div className={ styles.by_code_exam }>
                <p style={{ fontWeight: '500', fontSize: '30px' }}>จำนวนที่เลือก {data.length} ข้อ</p>
                <p style={{ fontWeight: '300', fontSize: '16px' }}>(สูงสุด 50 ข้อ)</p>
                {tableSubjectCode}
              </div>
              :
              <div>
                { (auth.role !== 'student' || examType !== 'competition')  ? inputCriterion : ''}
                { textSelectQuantity }
                {criterion !== 'none'
                  ? <Fragment>
                    <Divider/>
                    {(book !== undefined && examType === 'curriculumbook') ? textBookName : null } 
                    { textTotalQuantity }
                    <Divider/>
                    { inputQuantityList }
                  </Fragment>
                  : null}
              </div>
          }
        </Row>
      </div>
    )
  }
}

StepCriterion.propTypes = {
  isCurriculum: PropTypes.bool.isRequired,
  criterion: PropTypes.string.isRequired,
  dataForCriterion: PropTypes.array.isRequired,
  quantityList: PropTypes.array.isRequired,
  onChangeCriterion: PropTypes.func.isRequired,
}
 

export default StepCriterion
