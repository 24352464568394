/* eslint-disable no-undef */
import React, { Component, Fragment }  from 'react'
 
import ExamSetInfo from './examSetInfo'
import * as moment from 'moment'
import {
  Row,
  Col,
  Tabs,
  Typography
} from 'antd'
import styles from '../Exam.module.scss'
import './DetailExamSet.scss'
const { Paragraph } = Typography
const { TabPane }=Tabs

const genInfoByObject = (data, title, labelSpanCol=null, infoSpanCol=null) => { 
  if (!data) return null
   
  // if(title === 'เกณฑ์การจัดข้อสอบ') console.log(data)
  const labelCol=labelSpanCol
    ? { span: labelSpanCol }
    : {
      sm: 14,
      md: 6,
    }
  const infoCol=infoSpanCol
    ? { span: infoSpanCol }
    : {
      sm: 10,
      md: 18,
    }
  const children=[]
  for (var key in data) {
    children.push(
      <Fragment key={key}>
        <Col className='infoLabel' {...labelCol}>{key}</Col>
        <Col className='infoText' {...infoCol} >
          {data[key]}
        </Col>
      </Fragment>
    )
  }
  return (
    <Row className={styles.infoPanel}>
      { title ? <p className='infoTitle'>{title}</p> : null }
      { children }
      {/* <Divider style={{display: 'inline-block'}}/> */}
    </Row>
  )
}

const genInfoByArray=(arr) => { 
  if (!arr || arr.length < 1) return null 
  // const col = [16, 3, 3]
  const col = [10, 3, 3]
  const children = arr.map( (subArr, i) => subArr.map( (t, j) => { 
    return(
      <Col className={`infoSubText ${j===1?'textRight':''}`} key={`${i}_${j}`} span={col[j]} offset={ j === 2 ? 2 : 0}>
        <Paragraph ellipsis={{ rows: 1, expandable: false}} title={t} className={ styles.paragraph_detail_exam } style={ window.screen.width <= 991 ? null : { color: '#828282' }}>{t}</Paragraph>
      </Col>
    )
  }))
  return (
    <Row className={styles.infoPanel}>
      {children}
    </Row>
  )
}

class DetailExamSetTeacherComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() { 
    const { examDetail } = this.props

    const hour = Math.floor((examDetail.duration / 60) / 60)
    const minute=(examDetail.duration / 60)
    const examTime = examDetail.duration > 0 ? (examDetail.duration / 60) > 60 ?
      `${minute} นาที (${hour} ชั่วโมง ${minute % 60}`
      : `${minute} นาที` : 0 
    // Criterion's Type C (แข่งขัน) ------------------------------------------------------------------------------------------------
    // row 1
    const checkProjectOfExam = examDetail.competition ? examDetail.competition.project+'' : '-'
    const projectOfExam = checkProjectOfExam === 'undefined' ? 'ไม่มี' : checkProjectOfExam

    const infoSubjectTypeC = genInfoByObject({
      'วิชา': examDetail.subject ? examDetail.subject : '-' ,
      'ระดับชั้น/ช่วงชั้น': examDetail.grade ? examDetail.grade : '-',
      'โครงการที่แข่ง': projectOfExam ? projectOfExam : 'ไม่มี',
      'ปีที่แข่งขัน': examDetail.competition ? examDetail.competition.years.join(', ') : 'ไม่ระบุ',
    })
    // row 2 (เกณฑ์การจัดข้อสอบ)
    // none (ไม่ระบุ)
    let criterionText = ''
    let criterionList = []
    let newcriterionList = []
    if (examDetail.criterion === 'lesson') {
      criterionText = 'ตามบทเรียน'
      newcriterionList = examDetail.lessons.filter((less) => less.quantity !== 0)
      criterionList = newcriterionList.map(t => [t.name, t.quantity, 'ข้อ'])
    }else if (examDetail.criterion === 'indicator') {
      criterionText = 'ตัวชี้วัด'
      newcriterionList = examDetail.indicators.filter((t) => t.quantity !== 0)
      criterionList = newcriterionList.map(t => window.screen.width <= 991 ? [t.name, '',t.quantity] : [t.name, t.quantity, 'ข้อ'])
    } 
    const criteriaDetail = genInfoByArray([
      window.screen.width <= 991 ? [criterionText,'',examDetail.quantity] :[criterionText, examDetail.quantity, 'ข้อ'],
      ...criterionList,
    ])

    const infoCriteriaNoneTypeC = genInfoByObject({
      'รายละเอียด': criteriaDetail,
    }, '')

    // course (สาระการเรียนรู้)
    const criteriaDetailCourseTypeC = genInfoByArray([
      window.screen.width <= 991 ? [examDetail.criterion ,examDetail.quantity,''] :[examDetail.criterion, examDetail.quantity, 'ข้อ']
    ])
    const infoCriteriaCourseTypeC = genInfoByObject({
      'รายละเอียด': criteriaDetailCourseTypeC,
    }, 'เกณฑ์การจัดข้อสอบ')


    // Criterion's Type G (ตามหลักสูตร) ------------------------------------------------------------------------------------------------
    // row 1
    const infoSubjectTypeG = genInfoByObject({
      'วิชา': examDetail.subject ? examDetail.subject : '-' ,
      'ระดับชั้น/ช่วงชั้น': examDetail.grade ? examDetail.grade : '-',
      'ระดับข้อสอบ': examDetail.level ? examDetail.level.join(', ') : '-',
    })
    // row 2 (เกณฑ์การจัดข้อสอบ)
    // none (ไม่ระบุ)
    // const criteriaDetailNoneTypeG=genInfoByArray([
    //   [examDetail.quantity+' '+'ข้อ'],
    // ])
    // const infoCriteriaNoneTypeG=genInfoByObject({
    //   'รายละเอียด': criteriaDetailNoneTypeG,
    // }, 'เกณฑ์การจัดข้อสอบ')

    // course (สาระการเรียนรู้)
    // const criteriaDetailCourseTypeG=genInfoByArray([
    //   [examDetail.criterion, examDetail.quantity, 'ข้อ'],
    //   ['จำนวนและการดำเนินการ', '3', 'ข้อ'],
    //   ['จำนวนและการดำเนินการจำนวนและการดำเนินจำนวนและการดำเนินน...', '10', 'ข้อ'],
    // ])
    // const infoCriteriaCourseTypeG=genInfoByObject({
    //   'รายละเอียด': criteriaDetailCourseTypeG,
    // }, 'เกณฑ์การจัดข้อสอบ')

    // ----------------------------------------------------------------------------------------------------------------------------------
    // row3 (เงื่อนไขของชุดข้อสอบ)
    
    const infoCondition = genInfoByObject({
      'กำหนดเวลาในการทำข้อสอบ': examDetail.duration > 0 ? (examDetail.duration/60 > 60 ? `${examTime} นาที`  :  examTime) : 'ไม่ระบุ',
      'จำนวนชุดข้อสอบ': examDetail.examSetTotal ? `${examDetail.examSetTotal} ชุด` : '-',
      'สุ่มสลับลำดับข้อสอบ/แบบฝึกหัด': examDetail.shuffleMC ? 'ใช่' : 'ไม่ใช่',
      'กำหนดวันที่เริ่มการใช้ชุดแบบฝึกหัด': examDetail.startTime ? moment(examDetail.startTime).add(543,'years').format('DD MMMM YYYY HH:mm:ss','th') : 'ไม่เปิดใช้',
      'กำหนดวันที่สิ้นสุดการใช้ชุดแบบฝึกหัด': examDetail.endTime ? moment(examDetail.endTime).add(543,'years').format('DD MMMM YYYY HH:mm:ss','th') : 'ไม่เปิดใช้',
      'ให้นักเรียนแสดงวิธีทำ': examDetail.displayHowTo ? 'ใช่' : 'ไม่ใช่',
      'เฉลยข้อสอบหลังทำเสร็จ': examDetail.displaySolution ? 'เฉลย' : 'ไม่เฉลย',
      'จำนวนครั้งในการทำข้อสอบ': examDetail.oneTimeDone ? '1 ครั้ง' : 'ไม่จำกัด',
      'คำชี้แจงในการทำข้อสอบ': examDetail.description ? examDetail.description : '-',
    }, '')


    // Check Criterion
    const infoCriteriaTypeC = examDetail.criterion ? infoCriteriaNoneTypeC : infoCriteriaCourseTypeC
    // const infoCriteriaTypeG=examDetail.criterion ? infoCriteriaNoneTypeG : infoCriteriaCourseTypeG
    // Check Type
    const infoSubjectShow = examDetail.type === 'C' ? infoSubjectTypeC :  infoSubjectTypeG
    
    return (
      <div>
        <ExamSetInfo
          examDetail={examDetail}
          examSetDetail={this.state.examSetDetail}
          groupId={this.props.match.params.groupId}
          example
          doTest
          {...this.props}
        />

        <div className={styles.detailExamTeacher}>
          <Tabs
            defaultActiveKey='anchorSubject'
            animated={false}
            onChange={this.onTabs}
          >
            <TabPane tab='วิชาและระดับ' key='anchorSubject'>
              <div id='anchorSubject'>{infoSubjectShow}</div>
              {/* <div id='anchorCriteria'>{infoCriteriaTypeC}</div>
              <div id='anchorCondition'>{infoCondition}</div> */}
            </TabPane>
            <TabPane tab='จำนวนข้อสอบ' key='anchorCriteria'>
              <div id='anchorCriteria'>{infoCriteriaTypeC}</div>
            </TabPane>
            <TabPane tab='เงื่อนไขของชุดข้อสอบ' key='anchorCondition'>
              <div id='anchorCondition'>{infoCondition}</div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }
}

export default DetailExamSetTeacherComponent
