import React, { Component }  from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Button,
  Input,
Divider,
DatePicker,
Switch,
} from 'antd'
import moment from 'moment';
import StepperCircle from '../../../Display/StepperCircle'
import styles from '../../Exam.module.scss'
import '../CreateExamSet.scss'
const { TextArea } = Input

const labelCol = {
  sm: 10,
  md: 7,
}
const inputCol = {
  sm: 14,
  md: 17,
}

class StepCondition extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isDuration: false,
      idisplayEndTime: false,
      idisplayStartTime: false,
    }
  }

  render() {
    const { name, description, examSetTotal, duration, displaySolution, displayStartTime, displayEndTime, displayStartTime2, displayEndTime2, displayShuffle, displayHowTo, oneTimeDone, withoutRegistered, onChangeCondition ,auth, subject, grade, examType } = this.props
    const { isDuration, idisplayStartTime, idisplayEndTime, idisplayStartTime2, idisplayEndTime2, } = this.state
    const inputDuration = (
      <Row>
        <Col className='textLabel' {...labelCol} >กำหนดเวลาในการทำข้อสอบ</Col>
        <Col className='inputField' {...inputCol} >
          <div className='conditoin-switch-btn'>
            <Button
              type={isDuration ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => {
                this.setState({isDuration: true})
                onChangeCondition({'duration': 60})
              }}
            >
              ระบุ
            </Button>
            <Button
              type={!isDuration ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => {
                this.setState({isDuration: false})
                onChangeCondition({'duration': 0})
              }}
            >
              ไม่ระบุ
            </Button>
          </div>
        </Col>
        <Col className='inputField desktop' {...inputCol} offset={7} hidden={!isDuration} xs={24}>
          <StepperCircle value={ duration } onChange={ value => onChangeCondition({'duration': value}) } max={120}/>
          <div className = {styles.sum_duration}>
            นาที <br/>
            { duration < 60 ? `(${duration} นาที)`: duration%60 === 0 ? `(${Math.floor(duration/60)} ชม.)` : `(${Math.floor(duration/60)} ชม. ${duration%60} นาที)`}
          </div> 
        </Col>
        <Col className='inputField mobile' {...inputCol} hidden={!isDuration} lg={24} md={24} xs={24}>
          <StepperCircle value={ duration } onChange={ value => onChangeCondition({'duration': value}) } max={120}/>
          <div className = {styles.sum_duration}>
            นาที <br/>
            { duration < 60 ? `(${duration} นาที)`: duration%60 === 0 ? `(${Math.floor(duration/60)} ชม.)` : `(${Math.floor(duration/60)} ชม. ${duration%60} นาที)`}
          </div> 
        </Col>
      </Row>
    )
    const inputName = (
      <Row>
        <Col className='textLabel' {...labelCol} >ชื่อข้อสอบ</Col>
        <Col className='inputField' {...inputCol} >
          <Input 
            className={ styles.input_name_exam }
            // style={{width: 380}} 
            placeholder = { name ? name : `${subject} ${grade}` }
            // value={ name ? name : `${subject} ${grade}` } 
            onChange={ e => onChangeCondition({'name': e.target.value}) } maxLength={100}/>
        </Col>
      </Row>
    )

    const inputDescription = (
      <Row>
        <Col className='textLabel' {...labelCol} >คำชี้แจงในการทำข้อสอบ</Col>
        <Col className='inputField' {...inputCol} >
          <TextArea style={{width: 380}} rows={4} value={ description } onChange={ e => onChangeCondition({'description': e.target.value}) } maxLength={255} autosize={{minRows: 4, maxRows: 4}} />
        </Col>
      </Row>
    )

    const inputQuantity = (
      <Row>
        <Col className='textLabel' {...labelCol} >จำนวนชุดข้อสอบ</Col>
        <Col className='inputField' {...inputCol} >
          <StepperCircle value={ examSetTotal } onChange={ value => onChangeCondition({'examSetTotal': value}) } max={10}/>
        </Col>
      </Row>
    )

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}


// function onChange(value, dateString) {
//   console.log('Selected Time: ', value);
//   console.log('Formatted Selected Time: ', dateString);
// }

// function onOk(value) {
//   console.log('onOk: ', value);
// }

function disabledDate(current) {
  // Can not select days before today and today
  console.log(current)
  return false //current && current < moment().endOf('day');
}

// function disabledDateTime() {
//   return {
//     disabledHours: () => range(0, 24).splice(4, 20),
//     disabledMinutes: () => range(30, 60),
//     disabledSeconds: () => [55, 56],
//   };
// }

    const inputStartTime = (
      <Row>
        <Col className='textLabel' {...labelCol} >กำหนดวันที่เริ่มการใช้ชุดแบบฝึกหัด</Col>
        <Col className='inputField' {...inputCol} >          
<Switch checked={idisplayStartTime} checkedChildren="เปิด" unCheckedChildren="ปิด" onChange={async (a) => {
    console.log("onChange")
    console.log(idisplayStartTime2)

    if (idisplayStartTime2 === undefined || idisplayStartTime2 === null)
      await this.setState({idisplayStartTime2: moment()})

    await this.setState({idisplayStartTime: a})
  
await onChangeCondition({'displayStartTime': this.state.idisplayStartTime})
if (this.state.idisplayStartTime === true)
{
  await onChangeCondition({'displayStartTime2': moment(this.state.idisplayStartTime2).format('YYYY-MM-DD HH:mm:ss')})
}
else
{
  await onChangeCondition({'displayStartTime2': ''})
}

}} className={ styles.dtswitch } />
<DatePicker inputReadOnly onChange={async (a,b) => {

if (a === null)
{
  console.log("ERROR")
  await this.setState({idisplayStartTime: false, idisplayStartTime2: a})
}
else
{
  await this.setState({idisplayStartTime2: a})
}

await onChangeCondition({'displayStartTime': this.state.idisplayStartTime})
if (this.state.idisplayStartTime === true)
{
  await onChangeCondition({'displayStartTime2': moment(this.state.idisplayStartTime2).format('YYYY-MM-DD HH:mm:ss')})
}
else
{
  await onChangeCondition({'displayStartTime2': ''})
}

}} //onOk={onOk}
      format="YYYY-MM-DD HH:mm:ss"
      popupStyle={ styles.popupdtpicker }
      className={ styles.dtpicker }
      placeholder="วันเวลาที่เริ่ม"
      defaultValue={moment()}
      allowClear="false"
      value={idisplayStartTime2}
      // disabledDate={disabledDate}
      // disabledTime={disabledDateTime}
      showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
    />
        </Col>
      </Row>
    )



    const inputEndTime = (
      <Row>
        <Col className='textLabel' {...labelCol} >กำหนดวันที่สิ้นสุดการใช้ชุดแบบฝึกหัด</Col>
        <Col className='inputField' {...inputCol} >          
<Switch checked={idisplayEndTime} checkedChildren="เปิด" unCheckedChildren="ปิด" onChange={async (a) => {
    console.log("onChange")
    console.log(idisplayEndTime2)

    if (idisplayEndTime2 === undefined || idisplayEndTime2 === null)
      await this.setState({idisplayEndTime2: moment()})

    await this.setState({idisplayEndTime: a})

await onChangeCondition({'displayEndTime': this.state.idisplayEndTime})
if (this.state.idisplayEndTime === true)
{
  await onChangeCondition({'displayEndTime2': moment(this.state.idisplayEndTime2).format('YYYY-MM-DD HH:mm:ss')})
}
else
{
  await onChangeCondition({'displayEndTime2': ''})
}


}} className={ styles.dtswitch } />
<DatePicker inputReadOnly onChange={async (a,b) => {

if (a === null)
{
  console.log("ERROR")
  await this.setState({idisplayEndTime: false, idisplayEndTime2: a})
}
else
{
  await this.setState({idisplayEndTime2: a})
}

await onChangeCondition({'displayEndTime': this.state.idisplayEndTime})
if (this.state.idisplayEndTime === true)
{
  await onChangeCondition({'displayEndTime2': moment(this.state.idisplayEndTime2).format('YYYY-MM-DD HH:mm:ss')})
}
else
{
  await onChangeCondition({'displayEndTime2': ''})
}

}} //onOk={onOk}
      format="YYYY-MM-DD HH:mm:ss"
      popupStyle={ styles.popupdtpicker }
      className={ styles.dtpicker }
      placeholder="วันเวลาที่สุิ้นสุด"
      defaultValue={moment()}
      allowClear="false"
      value={idisplayEndTime2}
      // disabledDate={disabledDate}
      // disabledTime={disabledDateTime}
      showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
    />
        </Col>
      </Row>
    )





    const inputShuffle = (
      <Row>
        <Col className='textLabel' {...labelCol} >สุ่มสลับลำดับข้อสอบ/แบบฝึกหัด</Col>
        <Col className='inputField' {...inputCol} >
          <div className='conditoin-switch-btn'>
            <Button
              type={displayShuffle ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => onChangeCondition({'displayShuffle': true})}
            >ใช่</Button>
            <Button
              type={!displayShuffle ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => onChangeCondition({'displayShuffle': false})}
            >ไม่ใช่</Button>
          </div>
        </Col>
      </Row>
    )

    const inputDisplaySolution = (
      <Row>
        <Col className='textLabel' {...labelCol} >ให้นักเรียนแสดงวิธีทำ</Col>
        <Col className='inputField' {...inputCol} >
          <div className='conditoin-switch-btn'>
            <Button
              type={displayHowTo ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => onChangeCondition({'displayHowTo': true})}
            >ใช่</Button>
            <Button
              type={!displayHowTo ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => onChangeCondition({'displayHowTo': false})}
            >ไม่ใช่</Button>
          </div>
        </Col>
      </Row>
    )
    const inputDisplayHowTo = (
      <Row>
        <Col className='textLabel' {...labelCol} >เฉลยข้อสอบหลังทำเสร็จ</Col>
        <Col className='inputField' {...inputCol} >
          <div className='conditoin-switch-btn'>
            <Button
              type={displaySolution ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => onChangeCondition({'displaySolution': true})}
            >เฉลย</Button>
            <Button
              type={!displaySolution ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => onChangeCondition({'displaySolution': false})}
            >ไม่เฉลย</Button>
          </div>
        </Col>
      </Row>
    )

    const inputOneTimeDone = (
      <Row>
        <Col className='textLabel' {...labelCol} >จำนวนครั้งในการทำข้อสอบ</Col>
        <Col className='inputField' {...inputCol} >
          <div className='conditoin-switch-btn'>
            <Button
              type={oneTimeDone ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => onChangeCondition({'oneTimeDone': true})}
            >1 ครั้ง</Button>
            <Button
              type={!oneTimeDone ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => onChangeCondition({'oneTimeDone': false})}
            >ไม่จำกัด</Button>
          </div>
        </Col>
      </Row>
    )

    const inputWithoutRegistered = (
      <Row>
        <Col className='textLabel' {...labelCol} >ทำได้โดยไม่ต้องสมัครสมาชิก</Col>
        <Col className='inputField' {...inputCol} >
          <div className='conditoin-switch-btn'>
            <Button
              type={withoutRegistered ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => onChangeCondition({'withoutRegistered': true})}
            >อนุญาต</Button>
            <Button
              type={!withoutRegistered ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => onChangeCondition({'withoutRegistered': false})}
            >ไม่อนุญาต</Button>
          </div>
        </Col>
      </Row>
    )

    return (
      <div className='create-examset-condition'>
        <Row className={styles.createPanel}>
          { inputDuration }
          { inputName }
          { auth.role !== 'student' ? inputDescription : null }
          { examType !== 'custom' && auth.role !== 'student' ? inputQuantity : null}
          { auth.role !== 'student' ? <Divider/> : null}
          { auth.role !== 'student' ? inputShuffle : null}
          { auth.role !== 'student' ? inputStartTime : null}
          { auth.role !== 'student' ? inputEndTime : null}
          { auth.role !== 'student' ? <Divider/> : null}
          { auth.role !== 'student' ? inputDisplaySolution : null}
          { auth.role !== 'student' ? inputDisplayHowTo : null}
          { auth.role !== 'student' ? inputOneTimeDone : null}
          { auth.role === 'admin' || auth.role === 'superTeacher' ? inputWithoutRegistered : null }
        </Row>
      </div>
    )
  }
}

StepCondition.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  examSetTotal: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  displaySolution: PropTypes.bool.isRequired,
  displayStartTime2: PropTypes.string.isRequired,
  displayEndTime2: PropTypes.string.isRequired,
  displayStartTime: PropTypes.bool.isRequired,
  displayEndTime: PropTypes.bool.isRequired,
  displayShuffle: PropTypes.bool.isRequired,
  displayHowTo: PropTypes.bool.isRequired,
  oneTimeDone: PropTypes.bool.isRequired,
  withoutRegistered: PropTypes.bool.isRequired,
  onChangeCondition: PropTypes.func.isRequired,
}

export default StepCondition
